<template>
  <div>
    <div class="mb-8">
      <page-header :heading="pageHeading" :sub-heading="pageSubHeading"></page-header>
    </div>

    <component-guard
      :roles="['admin', 'manager']"
    >
      <create-folder></create-folder>
    </component-guard>

    <folder-management></folder-management>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import CreateFolder from '@/components/documentFolders/CreateFolder'
import FolderManagement from '@/components/documentFolders/FolderManagement'
import PageHeader from '@/components/PageHeader'

import i18n from '@/mixins/i18n.mixin'
import Page from '@/mixins/page.mixin'

export default {
  name: 'DocumentFolders',
  components: {
    CreateFolder,
    FolderManagement,
    PageHeader
  },
  mixins: [
    i18n,
    Page
  ],
  methods: mapActions('documentFolders', ['getDocumentFolders']),
  async created () {
    await this.getDocumentFolders()
  }
}
</script>
