<template>
  <div>
    <v-btn color="primary" @click="openSidePanel" :disabled="sidePanel">{{ create }}</v-btn>

    <side-panel v-model="sidePanel">
      <help-dialog
        :path="headingPath"
        tag="h2"
        css-classes="mb-4"
      ></help-dialog>

      <form-builder
        :config="form.config"
        :data="form.data"
        :errors="form.errors"
        :rules="form.rules"
        @submit="handleSubmit"
        @reset="handleReset"
      >
        <template #autocomplete.documentUuids.after="{ value }">
          <div class="mb-12">
            <document-selection :uuids="value"></document-selection>
          </div>
        </template>

        <template #form.footer="{ submit, submitText, valid }">
          <v-btn color="primary" @click="submit" :disabled="!valid">{{ submitText }}</v-btn>
        </template>
      </form-builder>
    </side-panel>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import DocumentSelection from '../DocumentSelection'
import FormBuilder from '../form/FormBuilder'
import HelpDialog from '../HelpDialog'
import SidePanel from '../SidePanel'

import { getHttpValidationErrorDetails } from '@/helpers/api.helper'
import { required } from '@/helpers/validators.helpers'

export default {
  name: 'CreateFolder',
  components: {
    DocumentSelection,
    FormBuilder,
    HelpDialog,
    SidePanel
  },
  data () {
    return {
      form: {
        config: [
          [
            [
              {
                name: 'name',
                component: 'v-text-field'
              },
              {
                name: 'comment',
                component: 'v-textarea'
              }
            ]
          ]
        ],
        data: {
          name: '',
          comment: ''
        },
        errors: {
          name: '',
          comment: ''
        },
        rules: {
          name: [
            required
          ]
        }
      }
    }
  },
  methods: {
    ...mapActions('documentFolders', ['createDocumentFolder', 'setDocumentFoldersSidePanel', 'setDocumentFoldersMembershipsSidePanel']),
    ...mapActions('messageQueue', ['queueSuccess']),
    openSidePanel () {
      this.sidePanel = true
    },
    async handleSubmit (data) {
      await this.createDocumentFolder({
        ...data,
        handlers: {
          '2xx': this.handleSuccess,
          422: (response) => {
            const errors = getHttpValidationErrorDetails(response)

            if (errors) {
              errors.forEach(({ field, message }) => {
                this.$set(this.form.errors, field, message)
              })
            }

            return this.$t('global.form.errorMessages.invalid')
          },
          400: () => this.$t('global.form.errorMessages.invalid')
        }
      })
    },
    handleReset (field) {
      this.$set(this.form.errors, field, '')
    },
    resetData () {
      this.$set(this.form, 'data', {
        name: '',
        comment: ''
      })
    },
    handleSuccess () {
      this.resetData()
      this.sidePanel = false

      return this.successMessage
    }
  },
  computed: {
    ...mapGetters('documentFolders', ['documentFolders']),
    ...mapState('documentFolders', ['documentFoldersSidePanel']),
    create () {
      return this.$tp('buttons.create')
    },
    headingPath () {
      return 'components.form.heading'
    },
    successMessage () {
      return this.$t(`${this.pageTranslationKey}.components.form.success`)
    },
    sidePanel: {
      get () {
        return this.documentFoldersSidePanel
      },
      set (value) {
        if (value) {
          this.setDocumentFoldersMembershipsSidePanel(!value)
        }
        this.setDocumentFoldersSidePanel(value)
      }
    }
  },
  inject: [
    '$tp',
    'pageTranslationKey'
  ],
  watch: {
    sidePanel (open) {
      if (!open) {
        // wait for closing animation to finish
        setTimeout(this.resetData, 200)
      }
    }
  }
}
</script>
