<template>
  <v-expansion-panels>
    <v-expansion-panel
      v-for="document in filteredDocuments"
      :key="document.uuid"
    >
      <v-expansion-panel-header>
        <template #actions>
          <v-icon class="expansion-panel-icon">$expand</v-icon>
        </template>

        <v-row align="center" no-gutters class="expansion-panel-header">
          <v-col cols="auto" class="mr-2">
            <v-icon>{{ $icons.mdiFilePdfBox }}</v-icon>
          </v-col>

          <v-col>
            <span class="font-weight-medium">
              {{ document.name }}
            </span>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-if="singleVersion">
          <embedded-object
            type="application/pdf"
            width="100%"
            :source="getFileUrl(getVersionUuid(document))"
            :download="getFileUrl(getVersionUuid(document), true)"
          >
            {{ getVersionUuid(document) }}
          </embedded-object>
        </div>

        <div v-else>
          <span class="text-overline">{{ overline }}</span>

          <v-expansion-panels>
            <v-expansion-panel
              v-for="({ validFrom, validTo, fileUuid, active }, i) in getVersions(document)"
              :key="`${document.uuid}-${i}`"
            >
              <v-expansion-panel-header>
                <template #actions>
                  <v-icon class="icon">$expand</v-icon>
                </template>

                <v-row no-gutters align="center" class="header">
                  <v-col :class="{ 'text--secondary': !active }">
                    {{ getValidityString(validFrom, validTo) }}
                  </v-col>

                  <v-col
                    v-if="active"
                    cols="auto"
                    class="pr-4 text--secondary"
                  >
                    {{ activeVersion }}
                  </v-col>

                  <v-col
                    cols="auto"
                  >
                    <v-icon
                      small
                      @click="openFile(fileUuid)"
                    >
                      $icons.mdiDownload
                    </v-icon>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <embedded-object
                  type="application/pdf"
                  width="100%"
                  :source="getFileUrl(fileUuid)"
                  :download="getFileUrl(fileUuid, true)"
                >
                  {{ fileUuid }}
                </embedded-object>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapActions } from 'vuex'

import EmbeddedObject from './EmbeddedObject'

import { getFileUrl } from '@/api/files.api'
import { timeIsWithin, currentTimeIsWithin, toLocaleDateString } from '@/helpers/datetime.helper'

export default {
  name: 'DocumentSelection',
  components: {
    EmbeddedObject
  },
  props: {
    uuids: {
      type: Array,
      required: true
    },
    singleVersion: {
      type: Boolean,
      default: false
    },
    date: {
      type: [String, Date],
      default: () => new Date()
    }
  },
  data () {
    return {
      getFileUrl,
      documents: [],
      dateOptions: { year: 'numeric', month: '2-digit', day: '2-digit' }
    }
  },
  created () {
    this.getDocuments()
  },
  methods: {
    ...mapActions('documents', ['getDocument']),
    getDocuments () {
      this.documents = []

      this.uuids.forEach(async (uuid) => {
        this.documents.push(await this.getDocument({ uuid }))
      })
    },
    getVersions (document) {
      return document.documentVersions.map(({ validFrom, validTo, fileUuid }) => {
        return { validFrom, validTo, fileUuid, active: currentTimeIsWithin(validFrom, validTo) }
      }).reverse()
    },
    getVersionUuid (document) {
      const version = document.documentVersions.find(({ validFrom, validTo }) => {
        return timeIsWithin(this.date, validFrom, validTo)
      })

      if (!version) {
        return null
      }

      return version.fileUuid
    },
    getValidityString (validFrom, validTo) {
      if (validTo) {
        return this.getFullValidityString(validFrom, validTo)
      }

      return this.getOpenValidityString(validFrom)
    },
    getFullValidityString (validFrom, validTo) {
      const start = toLocaleDateString(validFrom, this.dateOptions)
      const end = toLocaleDateString(validTo, this.dateOptions)

      return this.$t(`${this.translationKey}.versions.validity`, { start, end })
    },
    getOpenValidityString (validFrom) {
      const start = toLocaleDateString(validFrom, this.dateOptions)

      return this.$t(`${this.translationKey}.versions.openValidity`, { start })
    },
    openFile (uuid) {
      const url = getFileUrl(uuid, true)

      window.open(url, '_blank')
    }
  },
  computed: {
    translationKey () {
      return 'global.components.documentSelection'
    },
    activeVersion () {
      return this.$t(`${this.translationKey}.versions.active`)
    },
    overline () {
      return this.$t(`${this.translationKey}.overline`)
    },
    filteredDocuments () {
      if (!this.singleVersion) {
        return this.documents
      }

      return this.documents.filter((document) => this.getVersionUuid(document))
    }
  },
  watch: {
    uuids () {
      this.getDocuments()
    }
  }
}
</script>
