<template>
  <div class="mt-12">
    <help-dialog
      :path="headingPath"
      tag="h2"
      css-classes="mb-2"
    ></help-dialog>

    <data-table
      table-name="management.table"
      :data-table="dataTable"
      :actions="actions"
      :search-action="searchAction"
    >
      <template #item.name="{ item }">
        <div
          class="d-flex flex-row align-center"
        >
          <v-tooltip
            right
            v-if="item.numDocuments === 0"
          >
            <template #activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                color="red"
                class="mr-2"
              >
                {{ $icons.mdiAlertCircleOutline }}
              </v-icon>
            </template>
            <span
              v-html="noDocumentsTooltip"
            ></span>
          </v-tooltip>
          <copy :text="item.name"></copy>
        </div>
      </template>

      <template #item.createdOn="{ item }">
        <formatted-date :date="item.createdOn"></formatted-date>
      </template>

      <template #item.updatedOn="{ item }">
        <formatted-date :date="item.updatedOn"></formatted-date>
      </template>

      <template #item.uuid="{ item }">
        <copy :text="item.uuid" :display="shortenUuid(item.uuid)"></copy>
      </template>

      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-4">
          <v-row v-if="item.comment">
            <v-col cols="auto" md="6" lg="4" class="body-2 mb-4">
              <span class="font-weight-bold mb-2 d-inline-block">{{ commentHeading }}</span>
              <p>{{ item.comment }}</p>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <memberships :folder="item.uuid"></memberships>
            </v-col>
          </v-row>
        </td>
      </template>
    </data-table>

    <v-dialog
      width="600"
      v-model="deleteDialog"
    >
      <v-card
        class="px-4 py-4"
        v-if="deleteFolderItem"
      >
        <v-card-title>{{ deleteDialogTitle }}</v-card-title>

        <v-card-actions>
          <v-btn text color="primary" @click="resetDeleteDialog">{{ deleteDialogCancelText }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="confirmDelete">{{ deleteDialogConfirmText }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Copy from '../Copy'
import DataTable from '../DataTable'
import FormattedDate from '../FormattedDate'
import HelpDialog from '../HelpDialog'
import Memberships from './Memberships'

import UserRoleAwareMixin from '@/mixins/userRoleAware.mixin'

import { shortenUuid } from '@/helpers/utility.helper'

export default {
  name: 'FolderManagement',
  mixins: [
    UserRoleAwareMixin
  ],
  components: {
    Copy,
    DataTable,
    FormattedDate,
    HelpDialog,
    Memberships
  },
  data () {
    return {
      primaryField: 'uuid',
      searchTerm: '',
      headers: [
        { value: 'name' },
        { value: 'createdOn' },
        { value: 'updatedOn' },
        { value: 'uuid' },
        { value: 'actions', sortable: false, align: 'end' }
      ],
      deleteDialog: false,
      deleteFolderItem: null
    }
  },
  methods: {
    ...mapActions('documentFolders', ['deleteDocumentFolder']),
    searchHandler (term) {
      this.searchTerm = term
    },
    handleDelete (item) {
      this.deleteFolderItem = item
      this.deleteDialog = true
    },
    resetDeleteDialog () {
      this.deleteDialog = false
      this.deleteFolderItem = null
    },
    confirmDelete () {
      const uuid = this.deleteFolderItem.uuid
      const handlers = {
        '2xx': () => this.$t(`${this.tableTranslationKey}.actions.messages.delete.success`),
        '4xx': () => this.$t(`${this.tableTranslationKey}.actions.messages.delete.error`)
      }

      this.deleteDocumentFolder({ uuid, handlers })
      this.resetDeleteDialog()
    },
    shortenUuid
  },
  computed: {
    ...mapGetters('documentFolders', ['documentFolders', 'loading']),
    componentTranslationKey () {
      return `${this.pageTranslationKey}.components.management`
    },
    headingPath () {
      return `${this.componentTranslationKey}.heading`
    },
    tableTranslationKey () {
      return `${this.componentTranslationKey}.table`
    },
    dataTable () {
      return {
        headers: this.headers,
        items: this.documentFolders,
        loading: this.loading,
        showSelect: false,
        itemKey: this.primaryField,
        sortBy: 'updatedOn',
        sortDesc: true,
        disablePagination: true,
        hideDefaultFooter: true,
        options: {},
        search: this.searchTerm,
        showExpand: true,
        singleExpand: false
      }
    },
    actions () {
      return [
        { icon: this.$icons.mdiDelete, handler: this.handleDelete, disabled: this.isDeleteButtonDisabled, tooltip: this.deleteButtonTooltip }
      ]
    },
    searchAction () {
      return {
        label: this.$t(`${this.tableTranslationKey}.search.label`),
        hint: this.$t(`${this.tableTranslationKey}.search.hint`),
        handler: this.searchHandler
      }
    },
    deleteDialogTitle () {
      return this.$t(`${this.tableTranslationKey}.dialogs.delete.title`, {
        folder: this.deleteFolderItem?.name ?? ''
      })
    },
    deleteDialogCancelText () {
      return this.$t(`${this.tableTranslationKey}.dialogs.delete.buttons.cancel`)
    },
    deleteDialogConfirmText () {
      return this.$t(`${this.tableTranslationKey}.dialogs.delete.buttons.confirm`)
    },
    commentHeading () {
      return this.$t(`${this.componentTranslationKey}.commentHeading`)
    },
    isDeleteButtonDisabled (item) {
      return !this.isAdmin && !this.isManager
    },
    deleteButtonTooltip () {
      return this.$t(`${this.tableTranslationKey}.actions.delete`)
    },
    noDocumentsTooltip () {
      return this.$t(`${this.tableTranslationKey}.tooltips.noDocuments`)
    }
  },
  inject: [
    'pageTranslationKey'
  ]
}
</script>
